<template>
	<client-only>
		<div class="typeform-container flex flex-col h-full">
			<div class="mx-auto max-w-2xl w-full mb-4">
				<slot name="heading" />
			</div>
			<!-- progressBar -->
			<div
				v-if="
					localQuestionStats &&
					localQuestionStats.total &&
					localQuestionStats.open !== undefined &&
					localQuestionStats.total !== 100
				"
				id="progressBar"
				class="mx-auto max-w-2xl w-full"
				:class="[$isMobile.any ? 'mb-4 container' : 'mb-6']"
			>
				<div class="rounded-full relative bg-color-grey-lightest" style="height: 8px">
					<div
						class="absolute inset-0 rounded-l-full bg-color-main transition-all"
						:class="{
							'rounded-r-full': localQuestionStats.open === 0
						}"
						:style="`width: ${
							((localQuestionStats.total - localQuestionStats.open) / localQuestionStats.total) *
							100
						}%`"
					/>
				</div>
			</div>
			<!-- typeform content -->
			<div ref="messageContainer" class="typeform-inner w-full flex-1 px-4">
				<div id="messages" class="max-w-2xl mx-auto h-full relative overflow-x-hidden">
					<TransitionGroup :name="animateTo === 'left' ? 'animateToLeft' : 'animateToRight'">
						<ul :key="currentMsgIndex">
							<li ref="message" class="absolute w-full h-full">
								<div ref="inner" class="li-inner max-w-2xl mx-auto">
									<div
										v-if="
											msg.module === 'local-startWithName' &&
											user &&
											user.testerGroup &&
											user.testerGroup === 'B'
										"
										class="bg-color-main-light rounded-r-xl rounded-t-xl bubble relative"
									>
										<p class="p-2">
											Lass uns in wenigen Minuten deine Bewerbung {{ introText }}
											<strong>{{ matchObj.relation.obj.name }}</strong> erstellen.
										</p>
									</div>
									<div v-if="msg.question" class="question mb-4">
										<Question
											:msg="msg"
											:job-region="jobRegion"
											@close-cv-parsing="goToStagedQuestion"
											@go-to-cv-question="goToCvQuestion"
											@interview-done="$emit('interview-done')"
											@parsing-done="showExperienceOverview"
											@do-signup="doSignup"
											@do-login="doLogin"
										/>
									</div>
									<div
										v-if="msg.answerOptions"
										class="answer"
										:class="[{ 'mt-8': !!msg.lastError }, $isMobile.any ? 'pb-40' : 'pb-20']"
									>
										<ErrorBox v-if="msg.lastError">
											<template
												v-if="errorCodes.genericErrors[`${getErrorCode(msg.lastError)}_TITLE`]"
												#title
											>
												{{
													errorCodes.genericErrors[`${getErrorCode(msg.lastError)}_TITLE`] ||
													msg.lastError
												}}
											</template>

											{{
												minMaxLengthError(
													errorCodes.genericErrors[getErrorCode(msg.lastError) ?? 'N/A'] ||
														getErrorCode(msg.lastError) ||
														msg.lastError,
													msg.lastError && msg.lastError.minLength,
													msg.lastError && msg.lastError.maxLength
												)
											}}
										</ErrorBox>
										<div ref="answer">
											<InterviewAnswer
												ref="interviewAnswer"
												v-model="msg"
												:msg-index="currentMsgIndex"
												:answered="msg.currentAnswer"
												:send-answer="sendAnswer"
												:current-upload-status="currentUploadStatus"
												:match-obj-id="matchObj._id"
												:contact-person="contactPerson"
												:company-name="companyName"
												:job-region="jobRegion"
												:view-state="viewState"
												:parsed-once="parsedOnce"
											/>
										</div>
									</div>
									<div v-else-if="msg.parsedExperiences">
										<ul v-for="(data, index) in msg.parsedExperiences" :key="index" class="pb-4">
											<li v-if="data.title" class="font-bold">{{ data.title }}</li>
											<li v-if="data.company && data.company.name">{{ data.company.name }}</li>
											<li v-if="data.startDate">
												{{ new Date(data.startDate).toLocaleDateString('de-DE') }}
												<span v-if="data.isCurrent || data.endDate"
													>-
													{{
														data.isCurrent
															? 'laufend'
															: `${data.endDate ? new Date(data.endDate).toLocaleDateString('de-DE') : ''}`
													}}</span
												>
											</li>
										</ul>
									</div>
									<div v-else-if="msg.parsedEducations">
										<ul v-for="(data, index) in msg.parsedEducations" :key="index" class="pb-4">
											<li v-if="data.school && data.school.name" class="font-bold">
												{{ data.school.name }}
											</li>
											<li v-if="data.description">{{ data.description }}</li>
											<li v-if="data.startDate">
												{{ new Date(data.startDate).toLocaleDateString('de-DE') }} -
												{{
													!data.endDate
														? 'laufend'
														: `${
																data.endDate
																	? new Date(data.endDate).toLocaleDateString('de-DE')
																	: ''
															}`
												}}
											</li>
										</ul>
									</div>
								</div>
							</li>
						</ul>
					</TransitionGroup>
					<div
						v-if="
							$isMobile.any &&
							messages[currentMsgIndex] &&
							messages[currentMsgIndex].interviewStepInformation &&
							messages[currentMsgIndex].interviewStepInformation.text
						"
						class="bg-color-main-light mx-auto text-center text-xs py-2 fixed bottom-16 left-0 right-0 z-20"
					>
						<HokIcon
							class="inline"
							name="icon:lightbulb"
							:size="4"
							vertical-align="align-text-bottom"
						/>
						<p class="inline ml-2 mb-0">
							{{ messages[currentMsgIndex].interviewStepInformation.text }}
						</p>
					</div>
					<div
						v-if="
							msg.module === 'cvParsingExperienceOverview' ||
							msg.module === 'cvParsingEducationOverview'
						"
						class="w-full bg-color-main-light fixed bottom-16 left-0 h-8 flex items-center justify-center text-xs sm:text-base"
					>
						<HokIcon icon="icon-view" :size="$isMobile.phone ? 3 : 4" class="mx-2" />
						Du kannst deine
						{{ msg.module === 'cvParsingExperienceOverview' ? 'Erfahrungen' : 'Ausbildungen' }}
						jederzeit in deinem Profil bearbeiten!
					</div>
					<div
						class="bg-color-white w-full fixed bottom-0 left-0 right-0 z-20 h-16 border-t border-color-grey-light"
					>
						<div class="mx-auto max-w-2xl pt-3 px-4 flex justify-between">
							<HokButton
								id="interview-button-back"
								data-cy="interview-button-back"
								is-text
								:disabled="backButtonIsDisabled"
								color="main"
								@click="arrowBack"
								><HokIcon
									name="icon:arrow-long-left"
									:color="backButtonIsDisabled ? 'grey' : 'main'"
									:size="5"
									pointer
									:class="{ 'cursor-not-allowed': backButtonIsDisabled }"
									class="mr-2"
								/>
								Zurück</HokButton
							>
							<!-- was dynamic, one of: submitanswer, submit-empty, submitrange, submitlocation, singlechoicesubmit, multichoicesubmit, jobAlarmButton, submitdate -->
							<!-- :id="`submitanswer-${msg.identifier}-${msgIndex}`"-->
							<HokButton
								id="interview-button-next"
								data-cy="interview-button-next"
								color="main"
								:disabled="!!loading"
								:class="{
									invisible:
										msg.module === 'cvParsing' ||
										msg.module === 'startLoginOrSignUp' ||
										msg.module === 'login' ||
										msg.module === 'passwordSent'
								}"
								@click="
									msg.module === 'cvParsingExperienceOverview' ||
									msg.module === 'cvParsingEducationOverview'
										? resendAnswerGoToStaged(msg.module)
										: submitInterviewForm()
								"
							>
								Weiter
								<HokIcon
									name="icon:arrow-long-right"
									color="white"
									:size="5"
									pointer
									class="ml-2"
								/>
							</HokButton>
						</div>
					</div>
				</div>
			</div>
			<!--spinner-->
			<div v-if="loading" class="overlay">
				<Spinner />
			</div>
			<!-- errorModal-->
			<!-- do not tell user: PHONE_ALREADY_TAKEN or EMAIL_ALREADY_TAKEN -->
			<HokModal :scrollable="true" name="errorModal">
				<div v-if="getErrorCode(lastError) === 'PHONE_ALREADY_TAKEN'" class="text-center">
					<p>Diese Telefonnummer kann nicht verwendet werden. Bitte gib eine andere ein.</p>
					<HokButton id="changePhone" class="mt-4" color="main" @click="closeModal()"
						>Telefonnummer ändern</HokButton
					>
				</div>
				<div v-else-if="getErrorCode(lastError) === 'EMAIL_ALREADY_TAKEN'" class="text-center">
					<p>Diese E-Mail-Adresse kann nicht verwendet werden. Bitte gib eine andere ein.</p>
					<HokButton id="changeEmail" class="mt-4" color="main" @click="closeModal()"
						>E-Mail-Adresse ändern</HokButton
					>
				</div>
			</HokModal>
			<!--user guidance-->
			<HokModal
				:width="$isMobile.any ? '95%' : '350px'"
				name="user-guidance"
				transition="scale"
				@close-button="closeGuidance"
			>
				<h3 class="text-center text-color-main mb-8 pt-4">Bewirb dich in 4 Schritten!</h3>
				<div class="mb-10">
					<div v-for="data in guidanceData" :key="data.id" class="flex items-center space-x-4 mb-3">
						<NuxtImg loading="lazy" :src="data.image" class="aspect-square h-14 w-14" />
						<p class="mb-0">{{ data.text }}</p>
					</div>
				</div>
				<div class="flex justify-center pb-9">
					<HokButton color="main" data-cy="start-interview" @click="closeGuidance(true)"
						>Bewerbung starten!</HokButton
					>
				</div>
			</HokModal>
		</div>
	</client-only>
</template>

<script lang="ts">
import type {
	IAPIReAuthResult,
	IAPIOwnerSnippet,
	IAPILoginUser,
	IAPIMatchForJobSeeker,
	IInterviewQuestion,
	APITypeObjectId,
	APIObjectType,
	IAPIUserCvExperience,
	IAPIUserCvEducation
} from '@hokify/common';
import HokifyApply, {
	type IPWAInterviewQuestion,
	type ApplyMode
} from '@hokify/pwa-core-nuxt3/helpers/apply';
import { NoAnswerError } from '@hokify/pwa-core-nuxt3/errors/NoAnswerError';
import { delayedReject } from '@hokify/shared-components-nuxt3/lib/helpers/promise';
import { getCookie } from '@hokify/shared-components-nuxt3/lib/helpers/cookie';
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import { defineComponent, nextTick } from 'vue';
import type { PropType, ComponentPublicInstance } from 'vue';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { diff } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/diff';
import { errorCodes } from '@hokify/shared-components-nuxt3/lib/data/errorCodes';
import { mapStores } from 'pinia';
import { useInterviewStore } from '@/stores/interview';
import InterviewAnswer from '@/components/user/interviewAnswer/InterviewAnswer.vue';
import Question from '@/components/user/application/Question.vue';
import type { IStartInterviewResultDTO } from '@/stores/interview';
import { useUserProfileStore } from '@/stores/user-profile';
import { useTypeformStore } from '@/stores/typeform';
import { useUserProfileExtraStore } from '@/stores/user-profile-extra';
import { useFeatureFlagStore } from '@/stores/feature-flag';
import { useLoginStore } from '@/stores/login';

function hasVueElement(vue: any): vue is ComponentPublicInstance {
	return vue && !!vue.$el;
}

function isIInterviewQuestion(el): el is IInterviewQuestion {
	return el && el.identifier !== undefined;
}

/*
// remove this for now, as getting this to work needs quite some work + testing especially in the backend
function hasIncludes(obj: any): obj is { includes(value: []): boolean } {
	return typeof obj.includes === 'function';
} */

function hasSubmitInterviewForm(obj: any): obj is { submitInterviewForm(): void } {
	return obj && typeof obj.submitInterviewForm === 'function';
}

export enum InterviewCategory {
	Login = 1,
	PersonalBeforeCV = 2,
	RealCV = 3,
	Personal = 4,
	JobSpecific = 5,
	CVTypeQuestion = 6,
	Documents = 7,
	Overview = 8
}

export enum InterviewType {
	PROFILE = 'profile',
	ATTACHMENT = 'attachment',
	QUESTION = 'question',
	INFORMATION = 'information'
}

export default defineComponent({
	name: 'ApplyTypeform',
	components: {
		Question,
		ErrorBox,
		InterviewAnswer
	},
	emits: ['update-match-obj', 'interview-done', 'update-allow-cv-parsing'],
	data() {
		const scrollEvent = undefined as undefined | number;
		const lastError = { data: undefined, code: '' } as
			| { data?: any; code?: string }
			| boolean
			| null;
		const currentUploadStatus = false as boolean | number;
		const messages = [] as IPWAInterviewQuestion[];
		const containerElement = undefined as any;
		const localQuestionStats = undefined as any;
		const uref = undefined as any;
		const loading = false;
		const currentMsgIndex = 0;
		const loginEmail = undefined as string | undefined;
		const updateMode = undefined as any;
		const animateTo = 'left' as 'left' | 'right';
		const guidanceData = [
			{
				id: 'personal',
				text: 'Fülle deine persönlichen Daten aus.',
				image: '/head-emoji.png'
			},
			{
				id: 'skills',
				text: 'Beantworte Fragen zu deinen Skills.',
				image: '/lightbulb-emoji.png'
			},
			{
				id: 'cv',
				text: 'Lade deinen Lebenslauf hoch oder erstelle dein Profil.',
				image: '/cv-emoji.png'
			},
			{
				id: 'check',
				text: 'Überprüfe und versende deine Bewerbung',
				image: '/rocket-emoji.png'
			}
		];
		const cvParsingStarted = false;
		const stagedQuestion = undefined as undefined | IPWAInterviewQuestion;
		const stagedQuestionSeen = false;
		const parsedOnce = false;

		return {
			containerElement,
			localQuestionStats,
			uref,
			loading,
			messages,
			currentUploadStatus,
			currentMsgIndex,
			animateTo,
			loginEmail,
			updateMode,
			lastError,
			scrollEvent,
			errorCodes,
			guidanceData,
			cvParsingStarted,
			stagedQuestion,
			stagedQuestionSeen,
			parsedOnce
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		firstName(): string {
			return this.typeformStore.firstName;
		},
		lastName(): string {
			return this.typeformStore.lastName;
		},
		jobRegion(): string | undefined {
			return (
				(this.matchObj?.relation?.type === 'job' && this.matchObj.relation.obj?.internal?.region) ||
				undefined
			);
		},
		introText(): string {
			if (this.matchObj?.relation?.type === 'company') {
				return 'bei';
			}
			return 'für den Job';
		},
		contactPerson(): IAPIOwnerSnippet | undefined {
			if (this.matchObj?.relation.type === 'job') {
				return this.matchObj?.relation?.obj?.owners?.[0];
			}
			return undefined;
		},
		companyName(): string {
			if (this.matchObj?.relation.type === 'job') {
				return this.matchObj?.relation?.obj?.company?.name;
			}
			return this.matchObj?.relation?.obj?.name;
		},
		msg: {
			get() {
				return this.messages[this.currentMsgIndex];
			},
			set(value) {
				this.messages[this.currentMsgIndex].currentAnswer = value;
			}
		},
		backButtonIsDisabled(): boolean {
			// do not allow user to go back to register question, due to he can not change it anyway
			if (
				this.messages[this.currentMsgIndex - 1]?.module === 'register' ||
				this.messages[this.currentMsgIndex - 1]?.module === 'login' ||
				this.messages[this.currentMsgIndex]?.module === 'cvParsing'
			) {
				return true;
			}
			// can't go back any further
			return this.currentMsgIndex === 0;
		},
		cvParsingStartExists(): boolean {
			return this.messages.some(msg => msg?.module === 'cvParsing');
		},
		cvParsingExperienceList(): IAPIUserCvExperience[] {
			const exp = this.userProfileStore.obj?.cvInfo?.experiences || [];
			return exp.filter(el => el?.source === 'cv-parsing') || [];
		},
		cvParsingEducationList(): IAPIUserCvEducation[] {
			const edu = this.userProfileStore.obj?.cvInfo?.educations || [];
			return edu.filter(el => el?.source === 'cv-parsing') || [];
		},
		...mapStores(
			useInterviewStore,
			useUserProfileStore,
			useTypeformStore,
			useUserProfileExtraStore,
			useFeatureFlagStore,
			useLoginStore
		)
	},
	created() {
		let uref;
		if (this.$route && this.$route.query && this.$route.query.uref) {
			({ uref } = this.$route.query);
		} else if (
			import.meta.client &&
			(window.location.protocol === 'http:' || window.location.protocol === 'https:')
		) {
			uref = 'webapply';
		}
		this.uref = uref;

		if (this.answeredMsgs && Array.isArray(this.answeredMsgs)) {
			this.answeredMsgs.forEach(msg => {
				this.messages.push(msg);
			});
		}

		this.start();
	},
	async mounted() {
		if (import.meta.client) {
			this.$hokReCaptcha.initializeRecaptchaWidget();

			const msgRef = this.$refs.messageContainer;
			this.containerElement = (msgRef && hasVueElement(msgRef) && msgRef.$el) || msgRef;
		}

		// checks the last time the user has started an interview, if its longer than 30 days or the user is not logged in show the modal
		let userGuidanceShownDifference: null | number = null;
		setTimeout(() => {
			if (lsTest()) {
				const today = new Date();
				const guidanceShownDate = localStorage.getItem('userGuidanceShown');
				if (guidanceShownDate) {
					userGuidanceShownDifference = diff(new Date(guidanceShownDate), 'days', today);
				}
			}

			if (!this.user || userGuidanceShownDifference === null || userGuidanceShownDifference >= 30) {
				this.$modal.show('user-guidance');
				this.$trackUserEvent?.('view_intro_modal', {});
			}
		}, 300);

		this.localQuestionStats = this.questionStats;
	},
	methods: {
		start() {
			if (this.firstMsg) {
				this.pushMsg(this.firstMsg);

				// if we have a firstMsg because we restart the interview, make sure to focus it
				const msgIndex = this.messages.findIndex(
					message => message.identifier === this.firstMsg.identifier
				);
				if (msgIndex) {
					this.focus(msgIndex);
				}
			}

			if (this.guestLogin && !this.user) {
				const msg = HokifyApply.startWithName();

				this.pushMsg(msg);
			} else if (!this.firstMsg) {
				this.errorHandler({
					code: 'INVALID_FIRST_MSG',
					msg: 'something went wrong with the firstMsg'
				});
			}
		},
		doLogin() {
			this.loginStore.setLoginRedirect(`/${this.interviewMode}/${this.jobNrOrId}`);

			const msg = HokifyApply.startLogin();
			this.pushMsg(msg, true);
		},
		doSignup() {
			this.loginStore.setLoginRedirect(`/${this.interviewMode}/${this.jobNrOrId}`);

			const msg = HokifyApply.startSignup();
			this.pushMsg(msg, true);
		},
		getErrorCode(err: any): string | undefined {
			if (this.$nuxt.$isHokFetchResponseError(err)) {
				return err.response.data.code;
			}

			if ('data' in err && 'code' in err.data) {
				return err.data.code;
			}

			if (typeof err === 'object' && err !== null) {
				return err.code;
			}

			if (typeof err === 'string') {
				return err;
			}

			return undefined;
		},
		errorHandler(err) {
			const {
				public: { development }
			} = useRuntimeConfig();
			let error = err;
			if (development) {
				console.error('ERROR', err);
			}

			const errorCode = this.getErrorCode(err);

			if (!errorCode) {
				if (err?.err?.data) {
					// TODO check why we have this hack
					error = err.err.data;
				}
			}

			console.error(err);
			if (errorCode) {
				this.pushMsg({
					type: InterviewType.INFORMATION,
					identifier: 'error',
					baseIdentifier: 'error',
					module: 'local-error',
					moduleOptions: {},
					question: `Es ist ein Fehler aufgetreten: ${errorCode}`,
					category: InterviewCategory.Login,
					action: {
						text: 'Neu laden',
						cb() {
							if (process.env.pathForReload) {
								window.location.href = process.env.pathForReload;
							} else {
								window.location.reload();
							}
						}
					}
				});
			} else {
				const errString =
					err instanceof Error ? (err.toString?.() ?? err.message) : JSON.stringify(err);

				this.pushMsg({
					type: InterviewType.INFORMATION,
					identifier: 'fatalerror',
					baseIdentifier: 'fatalerror',
					module: 'local-fatal-error',
					moduleOptions: {},
					category: InterviewCategory.Login,
					question: `Es ist ein schwerwiegender Fehler aufgetreten. ${errString}`,
					action: {
						text: 'Neu laden',
						cb() {
							if (process.env.pathForReload) {
								window.location.href = process.env.pathForReload;
							} else {
								window.location.reload();
							}
						}
					}
				});
			}

			if (this.$sentry) {
				if (err instanceof Error) {
					this.$sentry.withScope(scope => {
						scope.setExtra('errorHandler', 'typeform');
						this.$sentry.captureException(err);
					});
				} else {
					this.$sentry.withScope(scope => {
						scope.setExtra('errorHandler', 'typeform');
						scope.setExtra('err', err);
						this.$sentry.captureMessage(
							`errorHandler${typeof err.toString === 'function' ? ` ${err.toString()}` : ''}`
						);
					});
				}
			}

			throw error;
		},
		setError(id, error) {
			// check if id found
			let myIndex;
			this.messages
				.slice(0)
				.reverse()
				.some((m, index) => {
					if (m.identifier === id) {
						myIndex = this.messages.length - (index + 1);
						return true;
					}
					return false;
				});

			let elm;
			let msg;
			if (typeof myIndex !== 'undefined') {
				this.focusNow(myIndex);

				// replace it
				this.messages[myIndex] = { ...this.messages[myIndex], lastError: error };

				msg = this.messages[myIndex];

				const msgRef = this.$refs?.message?.[myIndex] || this.$refs.message;
				elm = msgRef && msgRef.$el;
			} else {
				console.warn('nothing found with id', id, 'using last one');
				// not found, replace last one
				this.messages[this.messages.length - 1] = {
					...this.messages[this.messages.length - 1],
					lastError: error
				};

				msg = this.messages[this.messages.length - 1];

				const msgRef = this.$refs?.message?.[this.messages.length - 1] || this.$refs.message;
				elm = msgRef && msgRef.$el;
			}

			if (import.meta.client && msg && msg.module) {
				this.$trackUserEvent?.('interview_question_error', {
					questionType: msg.module.toLowerCase(),
					answerType: this.getErrorCode(error)
				});
			}

			if (elm) {
				elm.classList.remove('shake-it');
				// eslint-disable-next-line no-void
				void elm.offsetWidth;
				elm.classList.add('shake-it');

				// remove it after animation
				setTimeout(() => {
					elm.classList.remove('shake-it');
					// eslint-disable-next-line no-void
					void elm.offsetWidth;
				}, 820);
			}

			const { currentMsgIndex } = this;
			this.scrollToMsgIndex(currentMsgIndex);
		},
		minMaxLengthError(error: any, minLength?: number, maxLength?: number) {
			if (typeof error === 'object' && error !== null) {
				return error.data?.message ?? 'Es ist ein interner Fehler aufgetreten.';
			}
			if (error.includes('%{minLength}') && minLength) {
				return error.replace('%{minLength}', String(minLength));
			}
			if (error.includes('%{maxLength}') && maxLength) {
				return error.replace('%{maxLength}', String(maxLength));
			}
			return error;
		},
		introDone() {
			if (this.answeredMsgs && Array.isArray(this.answeredMsgs)) {
				this.answeredMsgs.forEach(msg => {
					this.messages.push(msg);
				});
			}

			this.start();
		},
		pushMsg(msg: IPWAInterviewQuestion, replaceExisting?: boolean) {
			// if we already have the name, send the answer to the server without bothering the user again
			if (msg.module === 'name' && this.firstName?.trim().length && this.lastName?.trim().length) {
				// this is async, therefore catch errors
				this.sendAlreadySavedName(msg.identifier).catch(this.$nuxt.$errorHandler);
				return;
			}

			// fill some data for reactivness
			if (!msg.lastError) {
				msg.lastError = undefined;
			}

			// ensure there is no other scroll event queued.
			if (this.scrollEvent !== undefined) {
				window.clearTimeout(this.scrollEvent);
			}

			if (!replaceExisting) {
				this.animateTo = 'left';
			}

			if (
				!msg.action &&
				(!msg.answerOptions || !(msg.answerOptions.length > 0)) &&
				msg.module !== 'cvParsing' &&
				msg.module !== 'cvParsingExperienceOverview' &&
				msg.module !== 'cvParsingEducationOverview' &&
				msg.module !== 'startLoginOrSignUp' &&
				msg.module !== 'passwordSent'
			) {
				throw new Error('invalid - no answeroptions');
			}

			let myIndex: number | undefined;
			if (replaceExisting) {
				// check if id found
				this.messages.some((m, index) => {
					if (m.identifier === msg.identifier) {
						myIndex = index;
						return true;
					}
					return false;
				});
			}

			if (typeof myIndex !== 'undefined') {
				this.currentMsgIndex = myIndex;

				// replace it
				this.messages[myIndex] = msg;
			} else {
				// this.currentMsgIndex = this.messages.length;

				if (
					!this.messages?.length ||
					msg?.identifier !== this.messages[this.messages.length - 1].identifier ||
					msg.identifier === 'userPassword' ||
					msg.identifier === 'userPasswordSent' ||
					msg.identifier === 'cancelLogin'
				) {
					this.messages.push(msg);
				} else {
					// this.currentMsgIndex = this.messages.length - 1;
					console.warn('do not add same question again');
				}

				if (import.meta.client && msg.module) {
					this.$trackUserEvent?.('interview_question_start', {
						questionType: msg.module.toLowerCase()
					});
				}
			}

			this.currentUploadStatus = false;
			this.scroll('left');
		},
		scrollToMsgIndex(currentMsgIndex: number) {
			if (import.meta.client) {
				if (this.scrollEvent !== undefined) {
					window.clearTimeout(this.scrollEvent);
				}
				this.scrollEvent = window.setTimeout(() => {
					this.scroll(undefined, currentMsgIndex);
				}, 100);
			}
		},
		async sendAnswer(
			msgId: string,
			formData?: any,
			refresh?: boolean,
			value?: string,
			resend?: boolean
		) {
			// remove this for now, as getting this to work needs quite some work + testing especially in the backend
			/*	let currentValue;
			if (formData) {
				for (const pair of formData.entries()) {
					// eslint-disable-next-line prefer-destructuring
					currentValue = pair[1];
				}
			} */

			// check if current msg is a base question
			if (msgId.includes('-0')) {
				const currentMsg = this.messages.find(message => message.identifier === msgId);
				// remove this for now, as getting this to work needs quite some work + testing especially in the backend
				// check if user changed currentAnswer
				/* if (
					currentMsg?.moduleOptions &&
					hasIncludes(currentMsg.moduleOptions) &&
					currentMsg.moduleOptions.includes(currentValue)
				) {
					console.log('value has not changed');
					return;
				} */

				// if user changed base question, delete all already saved sub questions
				this.messages = this.messages.filter(
					message =>
						message.baseIdentifier !== currentMsg?.baseIdentifier || message.identifier === msgId
				);
			}

			this.animateTo = 'left';

			// find the correct one
			let myIndex = this.messages.length - 1;

			// scroll to it (also scroll on it when answer is sent)
			if (msgId) {
				// if there are more than one, use the last one (reverse order!)
				this.messages
					.slice(0)
					.reverse()
					.some((m, index) => {
						if (m.identifier === msgId) {
							myIndex = this.messages.length - (index + 1);
							return true;
						}
						return false;
					});
			}

			// reset last error
			if (this.messages[myIndex].lastError) {
				this.messages[myIndex].lastError = null;
			}

			try {
				if (
					(!formData ||
						typeof formData.entries !== 'function' ||
						formData.entries().length === 0) &&
					msgId !== 'social-login-done' &&
					msgId !== 'startLogin'
				) {
					throw new NoAnswerError("Can't answer without an answer ;-)");
				}

				if (msgId === 'startLogin') {
					const msg = HokifyApply.startLoginOrSignup();
					this.pushMsg(msg, false);
					this.$trackUserEvent?.('interview_question_finish', {
						questionType: this.messages[myIndex].module.toLowerCase()
					});
					return;
				}

				if (msgId === 'social-login-done') {
					const interviewResult = await this.interviewStore.startInterview({
						relation: this.matchObj.relation,
						audienceId: this.audienceId
					});
					await this.finishLogin(interviewResult, myIndex);
					return;
				}

				if (msgId === 'welcome') {
					this.introDone();
					this.$trackUserEvent?.('interview_question_finish', {
						questionType: this.messages[myIndex].module
					});

					return;
				}

				if (
					msgId === 'userLogin' ||
					msgId === 'userSignup' ||
					msgId === 'cancelLogin' ||
					msgId === 'userPassword' ||
					msgId === 'userPasswordSent'
				) {
					this.loading = true;

					try {
						let loginResult: IAPIReAuthResult;
						let interviewResult: IStartInterviewResultDTO;

						let cookies;
						if (import.meta.client) {
							cookies = window.document.cookie;
						}
						const utm = (cookies && getCookie('utm', cookies)) || undefined;

						if (msgId === 'userSignup') {
							if (value) {
								this.loginEmail = value;
							} else if (typeof formData.get === 'function') {
								this.loginEmail = formData.get('email');
							}

							// if we already have the name, send it to the server together with the signup call
							if (this.firstName?.trim().length && this.lastName?.trim().length) {
								formData.append('firstname', this.firstName);
								formData.append('lastname', this.lastName);
							}

							({ loginResult, interviewResult } = await this.$hokReCaptcha.sendWithRecaptchaToken(
								this.interviewStore.handleApplySignUp,
								{
									messages: this.messages,
									formData,
									email: this.loginEmail,
									device: 'pwa-typeform',
									uref: this.uref || 'typeform',
									utm,
									loginAuthCode:
										this.$route?.query?.loginAuthCode ||
										(cookies && getCookie('loginAuthCode', cookies)),
									matchObj: this.matchObj,
									autoLogin: true
								},
								'signup'
							));
						} else {
							({ loginResult, interviewResult } = await this.$hokReCaptcha.sendWithRecaptchaToken(
								this.interviewStore.handleDoLogin,
								{
									messages: this.messages,
									user: formData.get('email'),
									password: formData.get('password'),
									device: 'pwa-typeform',
									uref: this.uref || 'typeform',
									utm,
									matchObj: this.matchObj
								},
								'signup'
							));
						}

						if (loginResult.newUser) {
							this.$trackGenericEvent?.('register_completed', {
								case: this.uref || 'typeform',
								mode: loginResult.user?.mode || 'jobseeker',
								loginMethod: this.loginEmail?.includes('@') ? 'e-mail' : 'sms'
							});
						} else {
							this.$trackGenericEvent?.('login', {
								case: this.uref || 'typeform',
								mode: loginResult.user?.mode || 'jobseeker',
								loginMethod: this.loginEmail?.includes('@') ? 'e-mail' : 'sms'
							});
						}

						this.loading = false;

						if (!this.loginEmail) {
							this.loginEmail = loginResult.user.general?.email;
						}

						await this.finishLogin(interviewResult, myIndex);
					} catch (err: any) {
						this.loading = false;
						if (err.data?.statusCode === 503) {
							this.$loginStore.setLoginIdentifier(this.loginEmail ? this.loginEmail : '');

							const msg = HokifyApply.passwordSent();
							this.pushMsg(msg, true);
						} else if (err.data) {
							this.setError(msgId, err.data);
						} else {
							this.errorHandler(err);
						}
					}

					// we are done
					return;
				}

				if (!(formData instanceof FormData)) {
					throw new TypeError('invalid formData');
				}

				this.loading = true;
				const isUpload =
					typeof formData.has === 'function' ? formData.has('media') : typeof value !== 'string';

				const data = await this.interviewStore
					.answerInterview({
						form: formData,
						msgId,
						matchObj: this.matchObj,
						resend,
						onUploadProgress: evt => {
							if (isUpload && evt.total) {
								this.currentUploadStatus = Math.round((evt.loaded / evt.total) * 100);
							}
						}
					})
					.catch(err => {
						this.loading = false;
						if (isUpload) {
							this.currentUploadStatus = false;
						}
						if (this.getErrorCode(err) && 'data' in err) {
							throw err.data;
						} else {
							throw err;
						}
					});

				this.loading = false;

				const { success, next, answered } = data;

				if (!success) {
					return this.errorHandler({
						code: 'INVALID_RESPONSE',
						msg: 'invalid response from answer-interview',
						err: data
					});
				}

				if (answered && !refresh) {
					let msg;
					if (typeof myIndex !== 'undefined') {
						// update the answer we have on the client, with the answer we get from the server
						// this could be e.g. another filename (cv.pdf instead of usernamedcv.pdf or similar)
						this.messages[myIndex] = {
							...this.messages[myIndex],
							...answered
						};
						msg = this.messages[myIndex];
						this.messages[myIndex].editing = false;
					} else {
						console.warn('nothing found with id ', msgId, 'using last one');
						// not found, replace last one
						this.messages[this.messages.length - 1] = {
							...this.messages[this.messages.length - 1],
							...answered
						};
						msg = this.messages[this.messages.length - 1];
						this.messages[this.messages.length - 1].editing = false;
					}

					if (import.meta.client && msg && msg.module) {
						const validAnalyticsTypes = ['file', 'button'];
						const isInvalid =
							msg.answerOptions &&
							msg.answerOptions.some(a => !validAnalyticsTypes.includes(a.type));

						let answer;

						if (typeof answered.currentAnswer === 'object') {
							Object.entries(answered.currentAnswer).forEach(item => {
								// item is an array of the object property's key and value
								answer += `${item[1]} `;
							});
						} else {
							answer = answered.currentAnswer && answered.currentAnswer.split(':');
						}

						const sendAnswer = !isInvalid ? answer && answer[0] : !!answered.currentAnswer; // send currentAnswer value only if it is valid for analytics

						if (msg.module === 'cv') {
							if (typeof sendAnswer === 'string' && sendAnswer === 'Erfolgreich hochgeladen') {
								this.cvParsingStarted = true;
								this.$trackUserEvent?.('interview_question_finish', {
									questionType: msg.module.toLowerCase(),
									answerType: 'file-cv'
								});
							} else if (typeof sendAnswer === 'string' && sendAnswer === 'Erfolgreich erstellt') {
								this.$trackUserEvent?.('interview_question_finish', {
									questionType: msg.module.toLowerCase(),
									answerType: 'fct-createcv'
								});
							} else if (
								typeof sendAnswer === 'string' &&
								sendAnswer.includes('Link zum hochladen via E-mail an')
							) {
								this.$trackUserEvent?.('interview_question_select', {
									questionType: 'cv',
									answerType: 'mail-cv-upload'
								});
							} else if (sendAnswer === false) {
								this.$trackUserEvent?.('interview_question_select', {
									questionType: 'cv',
									answerType: 'skip-cv'
								});
							}
						} else if (msg.module === 'pic') {
							if (
								typeof sendAnswer === 'string' &&
								sendAnswer.includes('Link zum hochladen via E-mail an')
							) {
								this.$trackUserEvent?.('interview_question_select', {
									questionType: 'pic',
									answerType: 'mail-pic-upload'
								});
							} else if (sendAnswer === false) {
								this.$trackUserEvent?.('interview_question_select', {
									questionType: 'pic',
									answerType: 'skip-profilepic'
								});
							}
						} else if (sendAnswer) {
							this.$trackUserEvent?.('interview_question_finish', {
								questionType: msg.module.toLowerCase(),
								answerType: String(sendAnswer).replace(/[., ].*@.*[., ]/g, '') // removes possible email addresses
							});
						}
					}
				}

				if (next) {
					if (this.msg.module === 'cv' && this.cvParsingStarted && this.allowCvParsing) {
						this.stagedQuestion = next;
						if (!this.cvParsingStartExists && !this.parsedOnce) {
							this.addCvParsingQuestion();
						} else {
							await this.scroll('left');
						}
					} else if (refresh) {
						this.pushMsg(next, true);
					} else if (this.updateMode && msgId === this.updateMode && import.meta.client) {
						this.pushMsg(next, true);
						this.updateMode = false;
					} else {
						this.pushMsg(next, false);
					}
				} else if (
					this.msg.module === 'cv' &&
					this.cvParsingStarted &&
					this.allowCvParsing &&
					!this.parsedOnce
				) {
					if (!this.cvParsingStartExists && !this.parsedOnce) {
						this.addCvParsingQuestion();
					} else {
						await this.scroll('left');
					}
				} else {
					this.$emit('interview-done');
				}

				// ensure update mode is reset after sending an answer
				this.updateMode = false;

				return data;
			} catch (err: any) {
				const errorCode = this.getErrorCode(err);
				if (errorCode === 'PHONE_ALREADY_TAKEN' || errorCode === 'EMAIL_ALREADY_TAKEN') {
					this.lastError = err;
					this.loginEmail = value;
					this.$modal.show('errorModal');
				}
				console.error('ERROR ApplyForm', err);

				try {
					this.setError(msgId, err);
				} catch (errBySetError) {
					this.setError(msgId, errBySetError);
				}
				return err;
			}
		},
		closeModal() {
			this.$modal.hide('errorModal');
		},
		arrowBack() {
			this.$trackUserEvent?.('interview_navigation_click_arrow', {});
			if (this.cvParsingStarted && this.cvParsingStartExists && this.msg?.module !== 'cvParsing') {
				this.$nextTick(() => {
					this.messages = this.messages.filter(msg => msg?.module !== 'cvParsing');
				});
				this.currentMsgIndex -= 1;
			}

			if (this.msg.module === 'login' || this.msg.module === 'register') {
				this.$nextTick(() => {
					this.messages.pop();
				});
			}

			this.currentUploadStatus = false;
			this.scroll('right');
		},
		async scroll(animateTo?: 'right' | 'left', index?: number) {
			if (animateTo) {
				this.animateTo = animateTo;
			}
			if (animateTo === 'left') {
				if (this.currentMsgIndex < this.messages.length - 1) {
					// only update progressBar, if we are not navigating inside multistep questions
					if (
						this.localQuestionStats?.open &&
						this.messages[this.currentMsgIndex].baseIdentifier !==
							this.messages[this.currentMsgIndex + 1].baseIdentifier &&
						this.messages[this.currentMsgIndex].baseIdentifier !== 'cvParsing' &&
						this.messages[this.currentMsgIndex].baseIdentifier !== 'cvParsingExperienceOverview' &&
						this.messages[this.currentMsgIndex].baseIdentifier !== 'cvParsingEducationOverview'
					) {
						this.localQuestionStats.open -= 1;
					}
					this.currentMsgIndex += 1;
				} else {
					return;
				}
			} else if (animateTo === 'right') {
				if (this.currentMsgIndex > 0) {
					// only update progressBar, if we are not navigating inside multistep questions
					if (
						this.localQuestionStats?.open &&
						this.messages[this.currentMsgIndex].baseIdentifier !==
							this.messages[this.currentMsgIndex - 1].baseIdentifier &&
						this.messages[this.currentMsgIndex].baseIdentifier !== 'cvParsing' &&
						this.messages[this.currentMsgIndex].baseIdentifier !== 'cvParsingExperienceOverview' &&
						this.messages[this.currentMsgIndex].baseIdentifier !== 'cvParsingEducationOverview'
					) {
						this.localQuestionStats.open += 1;
					}
					this.currentMsgIndex -= 1;
				} else {
					return;
				}
			}

			if (import.meta.client) {
				try {
					await Promise.race([
						new Promise<void>(resolve => {
							this.focusNow(index || this.currentMsgIndex, undefined, () => {
								resolve();
							});
						}),
						delayedReject(550, 'scrolling done handled not called within 550ms')
					]);
				} catch (err: any) {
					this.focusNow(index || this.currentMsgIndex);
				}
			}
		},
		focus(index) {
			// only set this message into focus, if it is not the currently active one already
			if (this.currentMsgIndex !== index) {
				this.focusNow(index, 'click');
			}
		},
		async focusNow(index, mode?: 'refocus' | 'click', _cb?: any) {
			if (typeof index === 'undefined') {
				throw new TypeError('INDEX NOT PROVIDED');
			}

			const initialMessageIndex = this.currentMsgIndex;

			const msgRef = (this.$refs.message && this.$refs.message[index]) || this.$refs.message;
			const liElement = msgRef && msgRef.$el;

			if (liElement) {
				await new Promise<void>((resolve, reject) => {
					/* const callBackFunction = (innerCb): false => {
						resolve();
						if (innerCb) {
							innerCb();
						}
						return false;
					}; */

					let duration = 500;
					if (mode === 'refocus') {
						duration = 0;
					}

					// const options = {
					// 	container: this.containerElement,
					// 	duration,
					// 	onDone: callBackFunction(cb)
					// };

					nextTick(() => {
						try {
							// calling callback after scrollIntoView done not possible yet https://github.com/w3c/csswg-drafts/issues/3744
							// TODO: check if call of callBackFunction(cb) is still needed anywhere
							liElement?.scrollIntoView({ behavior: 'smooth', block: 'start', duration });
							// VueScrollTo.scrollTo(liElement, options);
							resolve();
						} catch (err: any) {
							reject(err);
						}
					});
				});
			}

			// ensure that the current message index hasn't changed in the meantime and is the same since we called this function
			if (initialMessageIndex === this.currentMsgIndex) {
				// edit msg
				const msg = this.messages[index];
				// only if msg has "module" value
				if (mode === 'click' && msg && msg.editable !== false && msg.module) {
					this.updateMode = msg.identifier;
				}

				this.currentMsgIndex = index;
			}
		},
		async finishLogin(interviewResult: IStartInterviewResultDTO, myIndex) {
			const { next, finish, match, totalQuestions, cntOpenQuestions, allowCvParsing } =
				interviewResult;

			// if there is still the default amount saved for 'total' overwrite it with current value
			// add 1 because we are already animating to the next question at this point
			if (this.localQuestionStats?.total === 100) {
				this.localQuestionStats = {
					open: cntOpenQuestions && cntOpenQuestions + 1,
					total: totalQuestions
				};
			}

			this.$emit('update-allow-cv-parsing', allowCvParsing);

			if (match) {
				// after login, we need to set the match object that we got from the server
				this.$emit('update-match-obj', match);
			}

			if (next && isIInterviewQuestion(next)) {
				this.pushMsg(next as IPWAInterviewQuestion);
			} else if (finish) {
				this.$emit('interview-done');
			}

			this.$trackUserEvent?.('interview_question_finish', {
				questionType: this.messages[myIndex].module
			});
		},
		async sendAlreadySavedName(msgid) {
			// overwrite startWithName with correct ID we by now have got from server
			const existingMsg = this.messages.find(m => m.identifier === 'startWithName');
			if (existingMsg) {
				existingMsg.identifier = msgid;
			}
			const nameFormData = new FormData();
			nameFormData.append('firstname', this.firstName);
			nameFormData.append('lastname', this.lastName);
			await this.sendAnswer(msgid, nameFormData);
		},
		submitInterviewForm() {
			try {
				if (hasSubmitInterviewForm(this.$refs.interviewAnswer)) {
					this.$refs.interviewAnswer.submitInterviewForm();
				}
			} catch (err: any) {
				const myErr = { ...err, msg: this.msg, currentMsgIndex: this.currentMsgIndex };
				this.$nuxt.$errorHandler(myErr);
			}
		},
		closeGuidance(start?: boolean) {
			if (start) {
				this.$trackUserEvent?.('affirm_intro_modal', {});
				this.$modal.hide('user-guidance');
			} else {
				this.$trackUserEvent?.('close_intro_modal', {});
			}
			const today = new Date();
			localStorage.setItem('userGuidanceShown', today.toString());
		},
		addCvParsingQuestion() {
			this.$trackUserEvent?.('interview_question_start', {
				questionType: 'cvparsing'
			});
			this.parsedOnce = true;
			this.messages.splice(this.currentMsgIndex + 1, 0, {
				question: 'Dein Profil wird fertiggestellt',
				type: InterviewType.QUESTION,
				identifier: 'cvParsing',
				baseIdentifier: 'cvParsing',
				category: 6,
				module: 'cvParsing'
			});
			this.scroll('left');
		},
		goToStagedQuestion() {
			if (this.stagedQuestion && !this.stagedQuestionSeen) {
				this.pushMsg(this.stagedQuestion, false);
				this.stagedQuestionSeen = true;
			} else {
				this.scroll('left');
			}
		},
		async goToCvQuestion() {
			try {
				this.cvParsingStarted = false;
				this.parsedOnce = false;
				const cvId = this.userProfileStore.obj?.extras?.find(file => file.type === 'cv')?._id;
				await this.userProfileExtraStore.delExtra(cvId).then(() => {
					const cvQuestionIndex = this.messages.findIndex(msg => msg.module === 'cv');
					this.scroll('right', cvQuestionIndex);
					this.$nextTick(() => {
						this.messages = this.messages.filter(msg => msg?.module !== 'cvParsing');
					});
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async resendAnswerGoToStaged(module?) {
			if (module === 'cvParsingExperienceOverview' && this.cvParsingEducationList.length > 0) {
				await this.showEducationOverview();
			} else if (this.stagedQuestion) {
				this.goToStagedQuestion();
			} else {
				this.$emit('interview-done');
			}
		},
		async showExperienceOverview() {
			const showExperienceOverviewPushed = this.messages.some(
				msg => msg.module === 'cvParsingEducationOverview'
			);
			if (this.cvParsingExperienceList.length > 0 && !showExperienceOverviewPushed) {
				this.pushMsg(
					{
						question:
							'Basierend auf deinem Lebenslauf haben wir folgende Arbeitserfahrungen zu deinem Profil hinzugefügt:',
						type: InterviewType.QUESTION,
						identifier: 'cvParsingExperienceOverview',
						baseIdentifier: 'cvParsingExperienceOverview',
						category: 6,
						module: 'cvParsingExperienceOverview',
						parsedExperiences: this.cvParsingExperienceList
					},
					false
				);
			} else if (this.cvParsingEducationList.length > 0) {
				await this.showEducationOverview();
			} else {
				await this.resendAnswerGoToStaged();
			}
			this.$trackUserEvent?.('interview_question_finish', {
				questionType: 'cvparsing'
			});
		},
		async showEducationOverview() {
			const showEducationOverviewPushed = this.messages.some(
				msg => msg.module === 'cvParsingEducationOverview'
			);
			if (!showEducationOverviewPushed) {
				this.pushMsg(
					{
						question:
							'Basierend auf deinem Lebenslauf haben wir folgende Ausbildungen zu deinem Profil hinzugefügt:',
						type: InterviewType.QUESTION,
						identifier: 'cvParsingEducationOverview',
						baseIdentifier: 'cvParsingEducationOverview',
						category: 6,
						module: 'cvParsingEducationOverview',
						parsedEducations: this.cvParsingEducationList
					},
					false
				);
			} else {
				this.scroll('left');
			}
		}
	},
	props: {
		matchObj: { type: Object as PropType<IAPIMatchForJobSeeker>, required: true },
		guestLogin: { type: Boolean, default: false },
		answeredMsgs: { type: Array as PropType<IPWAInterviewQuestion[]>, default: null },
		firstMsg: { type: Object as PropType<IPWAInterviewQuestion>, default: () => {} },
		questionStats: { type: Object, default: () => {} },
		audienceId: {
			type: String as PropType<APITypeObjectId<APIObjectType.CompanyAudience>>,
			required: false,
			default: () => ''
		},
		viewState: {
			type: Object as PropType<{ mode: 'typeform' | 'match-overview' }>,
			default: () => {}
		},
		allowCvParsing: { type: Boolean, required: true },
		jobNrOrId: { type: [String, Number], required: true, default: '' },
		interviewMode: { type: String as PropType<ApplyMode>, required: true }
	}
});
</script>

<!-- TODO: rewrite with tailwind and get rid of this file-->
<style src="@hokify/pwa-core-nuxt3/assets/styles/apply-typeform.scss" lang="scss" scoped></style>
<style scoped lang="scss">
.animateToLeft-enter-active {
	animation: slideInRight 0.8s;
}

.animateToRight-leave-active {
	animation: slideOutRight 0.8s;
}

.animateToRight-enter-active {
	animation: slideInLeft 0.8s;
}

.animateToLeft-leave-active {
	animation: slideOutLeft 0.8s;
}

@keyframes slideInRight {
	from {
		transform: translate3d(100%, 0, 0);
		visibility: hidden;
	}
	to {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
}

@keyframes slideOutRight {
	from {
		visibility: visible;
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}

@keyframes slideInLeft {
	from {
		transform: translate3d(-100%, 0, 0);
		visibility: hidden;
	}

	to {
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideOutLeft {
	from {
		visibility: visible;
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translateX(-1px);
	}
	20%,
	80% {
		transform: translateX(2px);
	}
	30%,
	50%,
	70% {
		transform: translateX(-4px);
	}
	40%,
	60% {
		transform: translateX(4px);
	}
}

// this class is added via JS
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.shake-it {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.bubble {
	:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: -16px;
		width: 0;
		height: 0;
		border: 8px solid #d9f2f2;
		border-left-color: transparent;
		border-top-color: transparent;
		box-shadow: 0 0 0 transparent;
	}
}
</style>
